import React from "react"
import './Footer.css'
import footer_logo from '../Assets/icon.png'
import instagram from '../Assets/icon/instagram.png'
import tweeter from '../Assets/icon/tweeter.png'
import facebook from '../Assets/icon/facebook1.png'
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-logo">
                <img src={footer_logo} alt="" />
                <p>Brillent View</p>
            </div>
            <ul className="footer-link">
                <li>
                    <Link to="/term-and-condition">Term & Condition</Link>
                </li>
                <li>
                    <Link to="/about">About</Link> 
                </li>
            </ul>
            <div className="footer-social-media">
                <div className="footer-icon-container">
                    <img src={instagram} alt="" />
                </div>
                <div className="footer-icon-container">
                    <img src={tweeter} alt="" />
                </div>
                <div className="footer-icon-container">
                    <img src={facebook} alt="" />
                </div>
            </div>
            <div className="footer-copyright">
                <hr />
                <p>Contact</p>
                <p>brilliantview09@gmail.com, 91 86920 09286</p>
                <p>siddharth nagar, near muktabai hospital, bhatwadi , Ghatkopar (w),
                Mumbai-400084</p>
                <p>Copyright @2023 - All Right Reserved.</p>
            </div>
        </div>
    )
}

export default Footer