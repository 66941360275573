import React, { useEffect, useState } from 'react';
import './RelatedProduct.css';
import Item from '../Items/Item';

const RelatedProduct = ({ category }) => {
  const [relatedProducts, setRelatedProducts] = useState([]);

  useEffect(() => {
    console.log('Fetching related products for category:', category);
    // fetch(`http://localhost:4000/products/category/${category}`)
    //Railway setup
    fetch(`http://brillantviewweb-production.up.railway.app/products/category/${category}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data);
        setRelatedProducts(data);
      })
      .catch((error) => {
        console.error('Error fetching related products:', error);
      });
  }, [category]);

  return (
    <div className="relatedproduct">
      <h1>Related Products</h1>
      <hr />
      <div className="relatedproduct-item">
        {relatedProducts.map((item, i) => (
          <Item key={i} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
        ))}
      </div>
    </div>
  );
};

export default RelatedProduct;