import './App.css';
import Navbar from './Components/Assets/Navbar/Navbar';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Shop from './Pages/Shop';
import ShopCategory from './Pages/ShopCategory';
import Product from './Pages/Product';
import Cart from './Pages/Cart';
import LoginSignup from './Pages/LoginSignup';
import Footer from './Components/Footer/Footer';
import camara_banner from './Components/Assets/banner/banner_1.png';
import fire_alarm from './Components/Assets/banner/banner_2.png';
import smoke_alarm from './Components/Assets/banner/Banner_FD.png'
import TermsAndConditions from './Pages/TermsAndConditions';
import About from './Pages/About';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Setup_Repair from './Pages/UserForm';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  const location = useLocation();

  return (
    <div>
      <Navbar />
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={300}
        >
          <Routes location={location}>
            <Route path='/' element={<Shop />} />
            <Route path='/camera' element={<ShopCategory banner={camara_banner} category="camera" />} />
            <Route path='/fire-detector' element={<ShopCategory banner={fire_alarm} category="fire alarm" />} />
            <Route path='/smoke-detector' element={<ShopCategory banner={smoke_alarm} category="smoke alarm" />} />
            <Route path='/setup_repair' element={<Setup_Repair />}/>
            <Route path='/product' element={<Product />}>
              <Route path=':productId' element={<Product />} />
            </Route>
            <Route path='/cart' element={<Cart />} />
            <Route path='/login' element={<LoginSignup />} />
            <Route path='/term-and-condition' element={<TermsAndConditions />} />
            <Route path='/about' element={<About />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />}/>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </div>
  );
}

function AppWrapper() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default AppWrapper;
