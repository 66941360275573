import React, { useContext } from 'react';
import './Product-Display.css'
import star_icon from "../Assets/star-icon-m.png"
import star_icon_dull from "../Assets/star-icon-dull-m.png"
import { ShopContext } from '../../Context/ShopContext';

const ProductDisplay = (props) => {
    const {product} = props;
    const {addToCart} = useContext(ShopContext);
    return (
        <div className="productdisplay">
            <div className="productdisplay-left">
                <div className="productdisplay-img-list">
                    <img src={props.image} alt="" />
                    <img src={props.image} alt="" />
                    <img src={props.image} alt="" />    
                </div>
                <div className="productdisplay-img">
                    <img className= 'productdisplay-main-img' src={product.image} alt="" />
                </div>
            </div>
            <div className="productdisplay-right">
                <h1>{product.name}</h1>
                <div className="productdisplay-right-star">
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_icon} alt="" />
                    <img src={star_icon_dull} alt="" />
                    <p>(112)</p>
                </div>
                <div className="productdisplay-right-prices">
                    <div className="productdisplay-right-price-old">
                        ₹{product.old_price}
                    </div>
                    <div className="productdisplay-right-price-new">
                        ₹{product.new_price}
                    </div>
                </div>
                <div className="productdisplay-right-description">
                    {product.description}  {/* Display the description */}
                </div>
                {/* <div className="productdisplay-right-size">
                    <h1>Select Model</h1>
                    <div className="productdisplay-right-size">
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                    </div>
                </div> */}
                <button onClick={()=>{addToCart(product.id)}}>ADD TO CART</button>
                <p className="productdisplay-right-category">
                    <span>Category: <span>Camara</span><br /></span>
                    <span>Model: <span>1</span></span>
                </p>
            </div>
        </div>
    )
}

export default ProductDisplay