// src/pages/TermsAndConditions.jsx
import React from 'react';
import './TermsAndConditions.css';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
    return (
        <div className="terms-and-conditions">
            <h1>Terms and Conditions</h1>
            <hr />
            <p>Welcome to our security device shopping website. If you continue to browse and use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Brillent View's relationship with you in relation to this website.</p>
            
            <h2>Use of the Site</h2>
            <p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</p>

            <h2>Privacy Policy</h2>
            <p>Our privacy policy, which sets out how we will use your information, can be found at <Link to="/privacypolicy">link to privacy policy</Link>. By using this website, you consent to the processing described therein and warrant that all data provided by you is accurate.</p>

            <h2>Prohibitions</h2>
            <p>You must not misuse this website. You will not: commit or encourage a criminal offense; transmit or distribute a virus, trojan, worm, logic bomb or any other material which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; hack into any aspect of the service; corrupt data; cause annoyance to other users; infringe upon the rights of any other person's proprietary rights; send any unsolicited advertising or promotional material, commonly referred to as "spam"; or attempt to affect the performance or functionality of any computer facilities of or accessed through this website.</p>

            <h2>Intellectual Property, Software, and Content</h2>
            <p>The intellectual property rights in all software and content made available to you on or through this website remains the property of Brillent View or its licensors and are protected by copyright laws and treaties around the world. All such rights are reserved by Brillent View and its licensors.</p>

            <h2>Terms of Sale</h2>
            <p>By placing an order you are offering to purchase a product on and subject to the following terms and conditions. All orders are subject to availability and confirmation of the order price.</p>

            <h2>Disclaimer of Liability</h2>
            <p>The material displayed on this website is provided without any guarantees, conditions or warranties as to its accuracy. Unless expressly stated to the contrary to the fullest extent permitted by law Brillent View and its suppliers, content providers and advertisers hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity and shall not be liable for any damages whatsoever.</p>

            <h2>Governing Law and Jurisdiction</h2>
            <p>These terms and conditions are to be construed in accordance with the laws of India and in the event of any dispute or claim associated with these terms and conditions, that dispute or claim shall be subject to the exclusive jurisdiction of the India courts.</p>
            <p>Brillent View</p>

            <h2>Return and Refund Policy</h2>
      <p><strong>Last updated:</strong> 31 July</p>
      
      <p>Thank you for choosing to shop with us at Brillent View. We strive to provide you with the best products and services. However, if you are not entirely satisfied with your purchase, we're here to help.</p>
      
      <h3>1. Return Policy</h3>
      <ul>
        <li><strong>Eligibility:</strong> To be eligible for a return, your item must be unused, in the same condition that you received it, and in its original packaging. You must provide a receipt or proof of purchase.</li>
        <li><strong>Return Period:</strong> You have 7 days from the date of purchase to initiate a return. After this period, we cannot offer you a refund or exchange.</li>
        <li><strong>Non-Returnable Items:</strong> Certain types of goods are exempt from being returned, including:
          <ul>
            <li>Perishable goods (e.g., food, flowers)</li>
            <li>Downloadable software products</li>
            <li>Some health and personal care items</li>
            <li>Gift cards</li>
          </ul>
        </li>
      </ul>
      
      <h2>Return and Refund Policy</h2>
      <p><strong>Last updated:</strong> 31 July</p>
      
      <p>Thank you for choosing to shop with us at Brillent View. We are committed to providing high-quality security and safety products, including CCTV cameras, fire detectors, and smoke detectors. If you are not completely satisfied with your purchase, we are here to assist you.</p>
      
      <h3>1. Return Policy</h3>
      <ul>
        <li><strong>Eligibility:</strong> To be eligible for a return, your item must be unused, in the same condition that you received it, and in its original packaging. You must provide a receipt or proof of purchase.</li>
        <li><strong>Return Period:</strong> You have 7 days from the date of purchase to initiate a return. After this period, we cannot offer you a refund or exchange.</li>
        <li><strong>Non-Returnable Items:</strong> The following items are exempt from being returned:
          <ul>
            <li>Installed CCTV cameras, fire detectors, or smoke detectors</li>
            <li>Custom-configured security systems</li>
            <li>Products showing signs of use or installation</li>
            <li>Gift cards</li>
          </ul>
        </li>
      </ul>
      
      <h3>2. Refund Policy</h3>
      <ul>
        <li><strong>Refund Process:</strong> Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original method of payment within 4 days.</li>
        <li><strong>Partial Refunds:</strong> In certain situations, only partial refunds are granted (if applicable):
          <ul>
            <li>Any item not in its original condition, damaged, or missing parts for reasons not due to our error</li>
            <li>Any item that is returned more than 7 days after delivery</li>
          </ul>
        </li>
        <li><strong>Late or Missing Refunds:</strong> If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, as it may take some time before your refund is officially posted. If you've done all of this and still have not received your refund, please contact us at brilliantview09@gmail.com.</li>
      </ul>
      
      <h3>3. Exchanges</h3>
      <ul>
        <li><strong>Defective or Damaged Items:</strong> We only replace items if they are defective or damaged. If you need to exchange it for the same item, please send us an email at brilliantview09@gmail.com and send your item to: Room no 127, siddharth nagar, near muktabai hospital, bhatwadi , Ghatkopar (w),
        Mumbai-400084.</li>
      </ul>
      
      <h3>4. Shipping</h3>
      <ul>
        <li><strong>Return Shipping:</strong> You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</li>
        <li><strong>Shipping Time:</strong> Depending on where you live, the time it may take for your exchanged product to reach you may vary.</li>
      </ul>
      
      <h3>5. Contact Us</h3>
      <p>If you have any questions about our Return and Refund Policy, please contact us at brilliantview09@gmail.com.</p>
    
    </div>
    );
};

export default TermsAndConditions;
