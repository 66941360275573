import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 31 July</p>

      <h2>Introduction</h2>
      <p>
        Welcome to Brillient View. We value your privacy and are committed to protecting your personal information.
        This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website, purchase
        our products, or use our services.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We collect various types of information in order to provide and improve our services to you:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you make a purchase or create an account, we may collect personal
          details such as your name, email address, shipping address, phone number, and payment information.
        </li>
        <li>
          <strong>Browsing Data:</strong> We collect information about your interactions with our website, including
          your IP address, browser type, and pages viewed.
        </li>
        <li>
          <strong>Cookies:</strong> Our website uses cookies to enhance your browsing experience, remember your
          preferences, and track website usage.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>
        We use the information we collect for various purposes, including:
      </p>
      <ul>
        <li><strong>Order Processing:</strong> To process and fulfill your orders, including sending you order confirmations and delivery notifications.</li>
        <li><strong>Customer Service:</strong> To provide you with customer support and respond to your inquiries.</li>
        <li><strong>Marketing:</strong> To send you promotional offers, newsletters, and updates about our products and services. You can opt-out of marketing communications at any time.</li>
        <li><strong>Website Improvement:</strong> To analyze website usage and improve our services and user experience.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or share your personal information with third parties except in the following circumstances:
      </p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with trusted third-party service providers who assist us in operating our website, processing payments, and delivering products.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to a legal request, such as a court order or subpoena.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or part of our business, your information may be transferred as part of the transaction.</li>
      </ul>

      <h2>4. Security of Your Information</h2>
      <p>
        We implement a variety of security measures to protect your personal information. However, no method of
        transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute
        security.
      </p>

      <h2>5. Your Rights</h2>
      <p>
        You have the right to:
      </p>
      <ul>
        <li><strong>Access:</strong> Request a copy of the personal information we hold about you.</li>
        <li><strong>Correction:</strong> Request corrections to any inaccurate or incomplete information.</li>
        <li><strong>Deletion:</strong> Request the deletion of your personal information, subject to certain legal obligations.</li>
        <li><strong>Opt-Out:</strong> Opt-out of receiving marketing communications by following the unsubscribe instructions provided in our emails.</li>
      </ul>

      <h2>6. Third-Party Links</h2>
      <p>
        Our website may contain links to third-party websites. We are not responsible for the privacy practices or
        content of these external sites.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the effective
        date will be updated accordingly. We encourage you to review this policy periodically to stay informed about how
        we are protecting your information.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or how we handle your personal information,
        please contact us at:
      </p>
      <p>
        <strong>Brillient View</strong><br />
        siddharth nagar, near muktabai hospital, bhatwadi , Ghatkopar (w),
        Mumbai-400084<br />
        Email: brilliantview09@gmail.com<br />
        Phone: , 91 86920 09286
      </p>
    </div>
  );
};

export default PrivacyPolicy;
