import React, { useState } from 'react';
import axios from 'axios';
import './UserForm.css';

const UserForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    address: '',
    serviceType: 'repair',
    deviceType: 'camera',
    description: '',
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await axios.post('http://localhost:4000/submit', formData);
      // Railway Setup
      const response = await axios.post('http://brillantviewweb-production.up.railway.app/submit', formData);
      if (response.data.success) {
        setSubmissionSuccess(true);
        // Reset form
        setFormData({
          name: '',
          phone: '',
          address: '',
          serviceType: 'repair',
          deviceType: 'camera',
          description: '',
        });
      } else {
        setSubmissionSuccess(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmissionSuccess(false);
    }
  };

  return (
    <div className="user-form">
      <h2>Setup/Repair Form</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
        <input type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
        <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} required />
        <select name="serviceType" value={formData.serviceType} onChange={handleChange} required>
          <option value="repair">Repair</option>
          <option value="setup">Setup</option>
        </select>
        <select name="deviceType" value={formData.deviceType} onChange={handleChange} required>
          <option value="camera">Camera</option>
          <option value="fire-detector">Fire Detector</option>
          <option value="smoke-detector">Smoke Detector</option>
        </select>
        <textarea name="description" placeholder="Description" value={formData.description} onChange={handleChange} />
        <button type="submit">Submit</button>
      </form>
      {submissionSuccess === true && <p className="success-message">Submission successful!</p>}
      {submissionSuccess === false && <p className="error-message">Submission failed. Please try again.</p>}
    </div>
  );
};

export default UserForm;
