import React, { useEffect, useState } from "react";
import "./MyOrder.css"; // Import the CSS file

const MyOrder = () => {
    const [orders, setOrders] = useState([]);
    
    useEffect(() => {
        const fetchOrders = async () => {
            const baseURL = window.location.hostname === 'localhost'
                ? 'http://localhost:4000'
                : 'https://brillantviewweb-production.up.railway.app';

            try {
                const response = await fetch(`${baseURL}/user-orders`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("auth-token")}`,
                    },
                });

                if (!response.ok) {
                    console.error("Error fetching orders:", response.status, response.statusText);
                    throw new Error(`Failed to fetch orders: ${response.statusText}`);
                }

                const data = await response.json();
                if (data.success) {
                    setOrders(data.orders);
                } else {
                    console.error("Error fetching orders:", data.message || "Unknown error");
                }
            } catch (error) {
                console.error("Failed to fetch orders:", error.message);
            }
        };

        fetchOrders();
    }, []);

    return (
        <div className="orders-container">
            <h2>My Orders</h2>
            {orders.length === 0 ? (
                <p>No orders yet.</p>
            ) : (
                orders.map((order) => (
                    <div key={order._id} className="order-card">
                        <p><strong>Order ID:</strong> {order._id}</p>
                        <p><strong>User Name:</strong> {order.userId.name}</p>
                        <p><strong>Address:</strong> {order.userId.address}</p>
                        <p className="order-amount"><strong>Amount:</strong> ₹{order.amount}</p>
                        <p><strong>Status:</strong> {order.paymentStatus}</p>
                        <p className="order-date"><strong>Date:</strong> {new Date(order.orderDate).toLocaleDateString()}</p>
                        <ul className="order-items">
                            {order.items.map((item, index) => (
                                <li key={index}>
                                    <span>Product Name:</span> {item.productId.name}, <span>Quantity:</span> {item.quantity}
                                </li>
                            ))}
                        </ul>
                        <hr />
                    </div>
                ))
            )}
        </div>
    );
};

export default MyOrder;
