import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about-container">
      <h1 className="about-title">About Brillent View</h1>
      <p className="about-description">
        At Brillent View, we are dedicated to providing top-notch security solutions to keep your property safe and secure. 
        Specializing in security cameras, smoke detectors, and fire detectors, we offer products that meet the highest standards of reliability and performance. 
        Our team is committed to delivering exceptional service, not only in selling these essential devices but also in providing comprehensive setup and repair services to ensure your safety systems are always operational.
      </p>
      <p className="about-mission">
        Our mission is to bring peace of mind to our customers by ensuring that their homes and businesses are protected by the best security technologies available. 
        With a focus on innovation and customer satisfaction, Brillent View is your trusted partner for security and safety solutions.
      </p>
      <p className="about-vision">
        Whether you need a new installation, a system upgrade, or repair services, our expert technicians are here to assist you every step of the way. 
        Trust Brillent View to safeguard what matters most to you.
      </p>
    </div>
  );
};

export default About;
