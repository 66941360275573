import React from "react";
import CartItem from "../Components/CartItem/CartItem";

const Cart = () => {
    return (
        <div>
             <CartItem/>
        </div>
    )
}

export default Cart