import React, { useContext } from "react";
import dropdown from "../Components/Assets/drop-down.png";
import { ShopContext } from "../Context/ShopContext";
import Item from '../Components/Items/Item';
import './ShopCategory.css';

const ShopCategory = (props) => {
    const {all_product} = useContext(ShopContext);
    return (
        <div className="shop-category">
            <img src={props.banner} alt="" />
            <div className="shopcategory-indexSort">
                <p>
                    <span>Showing 1-20</span> out of 36 products
                </p>
                <div className="shopcategory-sort">
                    Sort by <img src={dropdown} alt="" />
                </div>
            </div>
            <div className="shopcategory-product">
                {all_product.map((item,i)=>{
                    if (props.category===item.category) {
                        return <Item key={i}  id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}/>
                    }
                    else{
                        return null;
                    }
                })}
            </div>
        </div>
    )
}

export default ShopCategory