import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SlidingBanner.css"
import banner1 from "../Assets/banner/1.png";
import banner2 from "../Assets/banner/2.png";
import banner3 from "../Assets/banner/3.png";


const SlidingBanner = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 20000); // 20 seconds

    return () => clearInterval(interval);
  }, );


  const banners = [
    { id: 1, image: banner1, alt: "Banner 1" },
    { id: 2, image: banner2, alt: "Banner 2" },
    { id: 3, image: banner3, alt: "Banner 3" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
    autoplay: true,
    autoplaySpeed: 20000,
  };

  return (
    <div className="banner-container">
      <Slider {...settings} initialSlide={slideIndex}>
        {banners.map((banner) => (
          <div key={banner.id} className="banner-slide">
            <img src={banner.image} alt={banner.alt} className="banner-image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SlidingBanner;