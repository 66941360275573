import React,{useEffect, useState} from "react"
import Item from '../Items/Item'
import './NewCollection.css'

const NewCollection = () => {

    const [new_collection,setNew_collection] =useState([]);

    useEffect(()=>{
        // fetch('http://localhost:4000/newcollection')
        // Railway Setup
        fetch('http://brillantviewweb-production.up.railway.app/newcollection')

        .then((response) =>response.json())
        .then((data)=>setNew_collection(data))
    },[])

    return (
        <div className="newcollection">
            <h1>New Collection</h1>
            <hr />
            <div className="collection">
                {new_collection.map((item,i)=>{
                    return <Item key={i}  id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price} />
                })}
            </div>
        </div>
    )
}

export default NewCollection