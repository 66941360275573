import React, { useState, useContext } from 'react';
import './CartItem.css';
import { ShopContext } from '../../Context/ShopContext';
import remove_icon from '../Assets/remove_icon.png';
import MyOrder from '../MyOrder/MyOrder';

const CartItem = () => {
    const { getTotalCartAmount, all_product, cartItems, removeCart } = useContext(ShopContext);
    const [isProcessing, setIsProcessing] = useState(false); // Add loading state
    const totalAmount = getTotalCartAmount();

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/checkout.js";
            script.onload = () => resolve(true);
            script.onerror = () => resolve(false);
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        if (isProcessing) return;
        setIsProcessing(true);
    
        const res = await loadRazorpayScript();
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            setIsProcessing(false);
            return;
        }
    
        const baseURL = window.location.hostname === 'localhost'
            ? 'http://localhost:4000'
            : 'https://brillantviewweb-production.up.railway.app';
    
        const userId = localStorage.getItem("user-id");
        const userName = localStorage.getItem("user-name");
        const address = localStorage.getItem("user-address");
    
        const items = all_product
            .filter(product => cartItems[product.id] > 0)
            .map(product => ({
                productId: product._id,
                quantity: cartItems[product.id],
                productName: product.name,
            }));
    
        // Fetch an order ID from Razorpay but do not save the order in DB yet
        const orderData = await fetch(`${baseURL}/create-order`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: totalAmount }),
        }).then((t) => t.json());
    
        const options = {
            key: "rzp_test_GS1EalEPfwGjq3",
            amount: orderData.amount,
            currency: orderData.currency,
            name: "Brilliant View",
            description: "Test Transaction",
            order_id: orderData.id,
            handler: async function (response) {
                // After successful payment, save the order to the database
                await fetch(`${baseURL}/save-order`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("auth-token")}`,
                    },
                    body: JSON.stringify({
                        userId,
                        userName,
                        address,
                        items,
                        orderId: orderData.id,
                        paymentId: response.razorpay_payment_id,
                        amount: totalAmount,
                    }),
                });
                setIsProcessing(false);
                alert("Order successfully placed!");
            },
            prefill: {
                name: "Test User",
                email: "testuser@example.com",
                contact: "9999999999",
            },
            theme: {
                color: "#3399cc",
            },
        };
    
        const rzp = new window.Razorpay(options);
        rzp.open();
    };
    
// During order creation in handlePayment:
    const items = all_product.map((product) => ({
        productId: product._id, // Use the MongoDB _id for each product
        quantity: cartItems[product.id], // quantity as defined in the cart
    }));

    return (
        <div className="cartitem">
            {/* Cart items display logic */}
            <div className="cartitems-format-main">
                <p>Product</p>
                <p>Title</p>
                <p>Price</p>
                <p>Quantity</p>
                <p>Remove</p>
            </div>
            <hr />
            {all_product.map((e) => {
                if (cartItems[e.id] > 0) {
                    return (
                        <div key={e.id}>
                            <div className="cartitem-format cartitems-format-main">
                                <img src={e.image} alt="" className='carticon-product-icon' />
                                <p>{e.name}</p>
                                {/* <p>{e.new_price}</p> */}
                                <p>₹{e.new_price * cartItems[e.id]}</p>
                                <button className='cartitem-quantity'>{cartItems[e.id]}</button>
                                {/* <img className='cartitem-remove-icon' src={remove_icon} alt="" onClick={() => removeCart(e.id)} /> */}
                                <span className="cartitem-remove-icon" onClick={() => removeCart(e.id)}>
                                 ❌
                                 </span>
                            </div>
                            <hr />
                        </div>
                    );
                }
                return null;
            })}
            
            <div className="cartitem-down">
                <div className="cartitem-total">
                    <h1>Cart Total</h1>
                    <p>Total: ₹{totalAmount}</p>
                    <button onClick={handlePayment} disabled={isProcessing}>
                    {isProcessing ? "Processing..." : "PROCEED TO CHECKOUT"}
                </button>
                </div>
            </div>
            <MyOrder />
        </div>
    );
};

export default CartItem;