import React from "react";
// import Hero from "../Components/Hero/Hero";
import Popular from "../Components/Poplular/Popular";
// import Offers from "../Components/Offers/Offers";
import NewCollection from "../Components/NewCollection/NewCollection";
import SlidingBanner from "../Components/Hero/SlidingBanner";

const Shop = () => {
    return (
        <div>
            {/* <Hero/> */}
            <SlidingBanner />
            <Popular/>
            {/* <Offers/> */}
            <NewCollection/>
        </div>
    )
}

export default Shop