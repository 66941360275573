import React, { useState } from "react";

const LoginSignup = () => {
    const [state, setState] = useState("Login");
    const [formData, setFormData] = useState({
        username: "",
        password: "",
        email: "",
        role: "user",
        adminName: "",
        address: "",   // New field for address
        phoneNo: "",   // New field for phone number
    });

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const validateForm = () => {
        if (!formData.email || !formData.password) {
            alert("Email and password are required");
            return false;
        }
        if (state === "Signup") {
            if (!formData.username) {
                alert("Username is required for signup");
                return false;
            }
            if (!formData.address) {
                alert("Address is required for signup");
                return false;
            }
            if (!formData.phoneNo) {
                alert("Phone number is required for signup");
                return false;
            }
        }
        if (state === "Login" && formData.role === "admin" && !formData.adminName) {
            alert("Admin name is required for admin login");
            return false;
        }
        return true;
    }

    const handleResponse = async (response) => {
        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(errorText);
        }
        return response.json();
    }

    const getBaseUrl = () => {
        return window.location.hostname === 'localhost'
            ? 'http://localhost:4000'
            : 'https://brillantviewweb-production.up.railway.app';
    }

    const login = async () => {
        if (!validateForm()) return;
        console.log("login function executed", formData);
        let responseData;
        try {
            const response = await fetch(`${getBaseUrl()}/${formData.role === 'admin' ? 'admin/' : ''}login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: formData.email,
                    password: formData.password,
                    adminName: formData.role === 'admin' ? formData.adminName : undefined,
                }),
            });
            responseData = await handleResponse(response);
            if (responseData.success) {
                localStorage.setItem('auth-token', responseData.token);
                localStorage.setItem('user-id', responseData.user._id); // Store userId in localStorage
                window.location.replace("/");

                if (formData.role === 'admin') {
                    localStorage.setItem('is-admin', 'true');
                }
                window.location.replace("/");
            } else {
                alert(responseData.errors);
            }
        } catch (error) {
            alert(error.message);
        }

        
    }

    const signup = async () => {
        if (!validateForm()) return;
        console.log("signup function executed", formData);
        let responseData;
        try {
            const response = await fetch(`${getBaseUrl()}/signup`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            responseData = await handleResponse(response);
            if (responseData.success) {
                localStorage.setItem('auth-token', responseData.token);
                localStorage.setItem('user-id', responseData.user._id); // Store userId in localStorage
                window.location.replace("/");
            } else {
                alert(responseData.errors);
            }
        } catch (error) {
            alert(error.message);
        }
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ border: '1px solid #ccc', padding: '20px', borderRadius: '10px', width: '300px', textAlign: 'center' }}>
                <h1>{state}</h1>
                <div style={{ marginBottom: '15px' }}>
                    {state === "Signup" && (
                        <>
                            <input name="username" value={formData.username} onChange={changeHandler} type="text" placeholder="Enter your name" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />
                            <input name="address" value={formData.address} onChange={changeHandler} type="text" placeholder="Enter your address" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />
                            <input name="phoneNo" value={formData.phoneNo} onChange={changeHandler} type="tel" placeholder="Enter your phone number" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />
                        </>
                    )}
                    <input name="email" value={formData.email} onChange={changeHandler} type="email" placeholder="Enter your email" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />
                    <input name="password" value={formData.password} onChange={changeHandler} type="password" placeholder="Enter your password" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />
                    {state === "Login" && formData.role === "admin" && <input name="adminName" value={formData.adminName} onChange={changeHandler} type="text" placeholder="Enter admin name" style={{ width: '90%', padding: '10px', marginBottom: '10px' }} />}
                </div>
                {state === "Login" && (
                    <div style={{ marginBottom: '15px', textAlign: 'left' }}>
                        <label>
                            <input type="radio" name="role" value="user" checked={formData.role === 'user'} onChange={changeHandler} />
                            User
                        </label>
                        <label style={{ marginLeft: '20px' }}>
                            <input type="radio" name="role" value="admin" checked={formData.role === 'admin'} onChange={changeHandler} />
                            Admin
                        </label>
                    </div>
                )}
                <button onClick={() => { state === "Login" ? login() : signup() }} style={{ width: '90%', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                    Continue
                </button>
                {state === "Signup" && (
                    <p style={{ marginTop: '10px' }}>Already have an account? <span onClick={() => { setState("Login") }} style={{ color: '#007BFF', cursor: 'pointer' }}>login here</span></p>
                )}
                {state === "Login" && (
                    <p style={{ marginTop: '10px' }}>Create an account <span onClick={() => { setState("Signup") }} style={{ color: '#007BFF', cursor: 'pointer' }}>Click here</span></p>
                )}
            </div>
        </div>
    );
}

export default LoginSignup;
