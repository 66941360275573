import React, { useContext, useState, useRef} from "react";
import './Navbar.css';
import logo from '../icon.png';
import cart from '../cart.png';
import { Link } from "react-router-dom";
import { ShopContext } from "../../../Context/ShopContext";
import nav_dropdown from '../../Assets/drop-down1.png';

const Navbar = () => {
    const { getTotalCartItems } = useContext(ShopContext);
    const [menu, setMenu] = useState("shop");
    const menuRef = useRef();
    const isAdmin = localStorage.getItem('is-admin') === 'true'; // Check if the user is an admin

    const dropdown_toggle = (e) =>{
        menuRef.current.classList.toggle('nav-menu-visible');
        e.target.classList.toggle('open');
    }

    const handleLogout = () => {
        localStorage.removeItem('auth-token');
        localStorage.removeItem('is-admin');
        window.location.replace('/');
    };

    return (
        <div className="navbar">
            <div className="nav-logo" onClick={() => setMenu("shop")}>
                <Link to='/'>
                <img src={logo} alt="logo" />
                </Link>
                {menu === "shop" ? <hr /> : null}
                <p>Brillent View</p>
            </div>
            <img className="nav-dropdown" onClick={dropdown_toggle} src={nav_dropdown} alt="" />
            <ul ref={menuRef} className="nav-menu">
                <li onClick={() => setMenu("shop")}>
                    <Link style={{ textDecoration: 'none' }} to='/'>Shop</Link>
                    {menu === "shop" ? <hr /> : null}
                </li>
                <li onClick={() => setMenu("camera")}>
                    <Link style={{ textDecoration: 'none' }} to='/camera'>Camera</Link>
                    {menu === "camera" ? <hr /> : null}
                </li>
                <li onClick={() => setMenu("fire-detector")}>
                    <Link style={{ textDecoration: 'none' }} to='/fire-detector'>Fire Detector</Link>
                    {menu === "fire-detector" ? <hr /> : null}
                </li>
                <li onClick={() => setMenu("smoke-detector")}>
                    <Link style={{ textDecoration: 'none' }} to='/smoke-detector'>Smoke Detector</Link>
                    {menu === "smoke-detector" ? <hr /> : null}
                </li>
                <li onClick={() => setMenu("setup_repair")}>
                    <Link style={{ textDecoration: 'none' }} to='/setup_repair'>Setup/Repair</Link>
                    {menu === "setup_repair" ? <hr /> : null}
                </li>
                {isAdmin && (
                <li onClick={() => setMenu("admin")}>
                    <a style={{ textDecoration: 'none' }} href='/admin/index.html' target="_blank" rel="noopener noreferrer">Admin</a>
                    {menu === "admin" ? <hr /> : null}
                </li>
                )}

            </ul>
            <div className="nav-login-cart">
                {localStorage.getItem('auth-token')
                    ? <button id="logout" onClick={handleLogout}>LogOut</button>
                    : <Link to='/login'><button>Login</button></Link>
                }
                <Link to='/cart'><img src={cart} alt="cart" /></Link>
                <div className="nav-cart-count">{getTotalCartItems()}</div>
            </div>
        </div>
    );
}

export default Navbar;
