import React, { createContext, useState, useEffect } from 'react';

export const ShopContext = createContext(null);

const ShopContextProvider = (props) => {
    const [all_product, setAll_Product] = useState([]);
    const [cartItems, setCartItems] = useState({});

    useEffect(() => {
        // fetch('http://localhost:4000/allproduct')
        // //Railway Setup
        // fetch('http://brillantviewweb-production.up.railway.app/allproduct')
        //     .then((response) => response.json())
        //     .then((data) => {
        //         setAll_Product(data);
        //         initializeCart(data);

        // Local Test
        const baseURL = window.location.hostname === 'localhost'
            ? 'http://localhost:4000/allproduct'  // Local URL
            : 'https://brillantviewweb-production.up.railway.app/allproduct';  // Production URL

        fetch(baseURL)
            .then((response) => response.json())
            .then((data) => {
                setAll_Product(data);
                initializeCart(data);
            })
            .catch((error) => console.error("Error fetching products:", error));
            
    }, []);

    const initializeCart = (products) => {
        let cart = {};
        for (let index = 0; index < products.length; index++) {
            cart[products[index].id] = 0;
        }
        setCartItems(cart);
    };

    const addToCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    };

    const removeCart = (itemId) => {
        setCartItems((prev) => ({ ...prev, [itemId]: Math.max(prev[itemId] - 1, 0) }));
    };

    const getTotalCartAmount = () => {
        let totalAmount = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0) {
                let itemInfo = all_product.find((product) => product.id === Number(item));
                if (itemInfo) {
                    totalAmount += itemInfo.new_price * cartItems[item];
                }
            }
        }
        return totalAmount;
    };

    const getTotalCartItems = () => {
        let totalItem = 0;
        for (const item in cartItems) {
            if (cartItems[item] > 0) {
                totalItem += cartItems[item];
            }
        }
        return totalItem;
    };

    const contextValue = {
        getTotalCartItems,
        getTotalCartAmount,
        all_product,
        cartItems,
        addToCart,
        removeCart
    };

    return (
        <ShopContext.Provider value={contextValue}>
            {props.children}
        </ShopContext.Provider>
    );
};

export default ShopContextProvider;
